import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  registerMicroApps,
  addGlobalUncaughtErrorHandler,
  start,
} from "qiankun"
Vue.config.productionTip = false
const host = window.location.host
// document.title = '爱采坊';
document.title = '加载中...';
let apps = [
  {
    name: 'bolan', // app name registered
    entry: '//localhost:8083',
    container: '#frame',
    activeRule: location => !location.pathname.startsWith('/mtdblad'),
  },
  {
    name: 'newinterview',
    entry: '//localhost:8081',
    container: '#frame',
    activeRule: '/mtdblad',
  },
]
if(process.env.NODE_ENV === 'dev') {
  apps = [
    {
      name: 'bolan', // app name registered
      entry: '//192.168.1.98/icaifang/',
      container: '#frame',
      activeRule: location => !location.pathname.startsWith('/mtdblad'),
    },
    {
      name: 'newinterview',
      entry: '//192.168.1.98/newinterview/',
      container: '#frame',
      activeRule: '/mtdblad',
    },
  ]
}
if (process.env.NODE_ENV === 'production') {
  apps = [
    {
      name: 'bolan', // app name registered
      // entry: '//www.icaifang.cn/icaifang/',
      entry: `//${host}/icaifang/`,
      container: '#frame',
      activeRule: location => !location.pathname.startsWith('/mtdblad'),
    },
    {
      name: 'newinterview',
      // entry: '//www.icaifang.cn/newinterview/',
      entry: `//${host}/newinterview/`,
      container: '#frame',
      activeRule: '/mtdblad',
    },
  ]
}
registerMicroApps(apps,{
  // qiankun 生命周期钩子 - 微应用加载前
  beforeLoad: (app) => {
    // 加载微应用前，加载进度条
    console.log("before load", app.name);
    return Promise.resolve();
  },
  // qiankun 生命周期钩子 - 微应用挂载后
  afterMount: (app) => {
    // 加载微应用前，进度条加载完成
    console.log("after mount", app.name);
    return Promise.resolve();
  },
})
addGlobalUncaughtErrorHandler((event) => {
  console.error(event);
  const { message: msg } = event;
  // 加载失败时提示
  if (msg && msg.includes("died in status LOADING_SOURCE_CODE")) {
    console.error("微应用加载失败，请检查应用是否可运行");
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#main-app')
start({
  prefetch:false
})


